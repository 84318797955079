import { render, staticRenderFns } from "./metamask.vue?vue&type=template&id=03320f14&scoped=true&"
import script from "./metamask.vue?vue&type=script&lang=js&"
export * from "./metamask.vue?vue&type=script&lang=js&"
import style0 from "./metamask.vue?vue&type=style&index=0&id=03320f14&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03320f14",
  null
  
)

export default component.exports