<script>
import Web3 from "web3";

export default {
    props: {
      userMessage: {
        type: String,
        default: "null",
      },
    },
    data() {
      return {
        web3: null,
        MetaMaskId: "1", // main net netID
        netID: "1", // user metamask id
        MetaMaskAddress: "", // user Address
        Web3Interval: null,
        AccountInterval: null,
        NetworkInterval: null,
        stateLog: null,
        isComplete: false,
        type: "INIT",
      };
    },
    methods: {
      checkWeb3() {
        let web3 = window.web3;
        if (typeof web3 === "undefined") {
          this.web3 = null;
          this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
        }
      },
      checkAccounts(network) {
        if (this.web3 === null) return;
        this.web3.eth.getAccounts((err, accounts) => {
          if (err != null)
            return this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
          if (accounts.length === 0) {
            this.MetaMaskAddress = "";
            this.Log(this.MetamaskMsg.EMPTY_METAMASK_ACCOUNT, "NO_LOGIN");
            return;
          }
          if (this.MetaMaskAddress !== "" && this.MetaMaskAddress !== accounts[0]) {
            this.$emit("onComplete", {
              type: this.type,
              metaMaskAddress: accounts[0],
              message: this.MetamaskMsg.ACCOUNT_CHANGE,
              netID: this.netID,
              addr: this.MetaMaskAddress,
              chainId: this.netID,
              network: network,
              walletProvider: "MetaMask"
            });
          }
          this.MetaMaskAddress = accounts[0]; // user Address
        });
      },
      checkNetWork() {
        this.web3.eth.net.getId((err, netID) => {
          // Main Network: 1
          // Ropsten Test Network: 3
          // Kovan Test Network: 42
          // Rinkeby Test Network: 4
          // Goerli Test Network: 5
          if (err != null)
            return this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
          this.netID = netID; //User MetaMask's current status
          if (this.MetaMaskAddress !== "" && netID === "1")
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "MAINNET");
          if (this.MetaMaskAddress !== "" && netID === "3")
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "ROPSTEN");
          if (this.MetaMaskAddress !== "" && netID === "42")
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "Kovan");
          if (this.MetaMaskAddress !== "" && netID === "4")
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "RINKEBY");
          if (this.MetaMaskAddress !== "" && netID === "5")
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "GOERLI");
          if (this.MetaMaskAddress !== "")
            this.Log(this.MetamaskMsg.METAMASK_MAIN_NET, "MAINNET");
        });
      },
      Log(msg, type = "") {
        const letType = type;
        if (letType === this.type) return;
        const message = this.userMessage === "null" ? msg : this.userMessage;
        this.type = type;
        this.$emit("onComplete", {
          type,
          metaMaskAddress: this.MetaMaskAddress,
          message,
          netID: this.netID,
          addr: this.MetaMaskAddress,
          chainId: this.netID,
          network: this.getNetwork(this.netID),
          walletProvider: "MetaMask"
        });
      },
      web3TimerCheck(web3, network) {
        this.web3 = web3;
        this.checkAccounts(network);
        this.checkNetWork();
        this.Web3Interval = setInterval(() => this.checkWeb3(), 1000);
        this.AccountInterval = setInterval(() => this.checkAccounts(network), 1000);
        this.NetworkInterval = setInterval(() => this.checkNetWork(), 1000);
      },
      async onClick(network) {
        this.MetamaskMsg = {
          LOAD_MATAMASK_WALLET_ERROR: "Loading metamask error, please try later",
          EMPTY_METAMASK_ACCOUNT:
            "Please log in to your metamask to continue with this app.",
          NETWORK_ERROR: "The connection is abnormal, please try again",
          METAMASK_NOT_INSTALL: "Please install metamask for this application",
          METAMASK_TEST_NET: "Currently not in the main network.",
          METAMASK_MAIN_NET: "Currently Main network",
          USER_DENIED_ACCOUNT_AUTHORIZATION: "User denied account authorization",
          ACCOUNT_CHANGE: 'User Account Changed',
        }
        if (window.ethereum) {
          window.web3 = new Web3(ethereum);
          try {
            let chainId = this.getChainId(network)
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{
                chainId: Web3.utils.toHex(chainId)
              }]
            }).then(data => {
              ethereum.request({ method: 'eth_requestAccounts' });
              this.web3TimerCheck(window.web3, network);
            }).catch(error => {
              if(!window.web3.currentProvider.isMetaMask){
                window.open(process.env.VUE_APP_METAMASK_DEEPLINK, "_blank");
              }
              else if (error.code === 4902) {
                let params = this.getParamsByChainId(chainId);
                window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [params]
                }).then(data => {
                  ethereum.request({ method: 'eth_requestAccounts' });
                  this.web3TimerCheck(window.web3, network);
                })
              }
            })
          } catch (error) {
            console.log(error)
            this.Log(
              this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION,
              "USER_DENIED_ACCOUNT_AUTHORIZATION"
            );
          }
        } else if (window.web3) {
          window.web3 = new Web3(web3.currentProvider);
          this.web3TimerCheck(window.web3, network);
        } else {
          this.web3 = null;
          window.open(process.env.VUE_APP_METAMASK_DEEPLINK, "_blank");
        }
      },
      getNetwork(chainId){
        let network = 'Ethereum';

        if(chainId.toString() === process.env.VUE_APP_ETHEREUM_CHAIN_ID){
          network = 'Ethereum';
        }else if(chainId.toString() === process.env.VUE_APP_POLYGON_CHAIN_ID){
          network = 'Polygon'
        }

        return network;
      },
      getChainId(network){
        return ({
          'ethereum': process.env.VUE_APP_ETHEREUM_CHAIN_ID,
          'polygon': process.env.VUE_APP_POLYGON_CHAIN_ID,
        })[network.toLowerCase()] ?? process.env.VUE_APP_ETHEREUM_CHAIN_ID
      },
      getParamsByChainId(chainId){
        // Metamask 네트워크 추가시 필요한 정보
        if(chainId === 56 || chainId === '56' || chainId === '0x38'){
          //BNB Chain
          return {
            chainId: '0x38',
            chainName: 'Binance Smart Chain',
            nativeCurrency: {
              name: 'Binance Coin',
              symbol: 'BNB',
              decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com']
          };
        }else if(chainId === 80001 || chainId === '80001' || chainId === '0x13881') {
          // POLYGON TESTNET
          return {
            chainId: '0x13881',
            chainName: 'Mumbai Test Network',
            nativeCurrency: {
              name: 'Polygon Coin',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
            blockExplorerUrls: ['https://mumbai.polygonscan.com/']
          }
        } else if(chainId === 137 || chainId === '137' || chainId === '0x89'){
          // POLYGON MAINNET
          return {
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: 'Polygon Coin',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorerUrls: ['https://polygonscan.com/']
          }
        }
      }
    },
  };
</script>
<template>
  <div id="vue-metamask"></div>
</template>
<style scoped>
  #vue-metamask {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
</style>
